import { useLocation } from "@reach/router";
import { StyleGuideToolFaqData } from "components/FAQ/config";
import Layout from "components/layout";
import ToolFaq from "components/QualityTools/ToolFaq";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect, useRef, useState } from "react";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import { navigate } from "gatsby";
import CleanButton from "assets/cleanBtn.svg";
import Loader from "components/loader";
import StyleOutput from "components/StyleGuideTool/styleOutput";

const Result = () => {
  const [sourceText, setSourceText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [error, setError] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [inputLang, setInputLang] = useState("");

  const textareaRef = useRef(null);


  const handleTextChange = (e) => {
    const text = e.target.value;

    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    if (words.length > 1000) {
      e.target.value = words.slice(0, 1000).join(" ");
      return;
    }

    setWordCount(words.length);
    setSourceText(text);
    setOutputText("");
    adjustHeight();
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 380);
    textarea.style.height = `${newHeight}px`;
  };
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");
  const displayShareId = UpdatreshareId;

  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        const { user_text: sourceText, source_language_code: languagecode } =
          response.meta;

        setSourceText(sourceText);
        setInputLang(languagecode);
        setOutputText(response?.result);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoader(false);
      }
    };
    fatchAnalysis();
  }, []);


  if (loader)
    return (
      <div className="grid place-content-center h-[calc(100vh-150px)]">
        <Loader />
      </div>
    );
  return (
    <Layout>
      <SEO
        title="Translation Style Guide Generator"
        description="Create detailed style guides for your translation projects with our AI Style Guide Generator. Keep tone, terminology, and formatting consistent."
        slug="/tools/style-guide-generator"
      />
      <div>
        <div className="w-full py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
          <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
            Style Guide Generator
          </h1>
          <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
            Generate an AI Style Guide to Save Time and Reduce Errors
          </h2>

          <div className="mt-12 flex lg:flex-row flex-col gap-4">
            <div className="relative shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans">
              <div className="flex justify-between">
                <span className="font-bold text-lg text-[#6E6E6E]">
                  {" "}
                  {inputLang ? "Source Text" : "Source Link"}
                </span>
                {sourceText && (
                  <img
                    src={CleanButton}
                    className="cursor-pointer"
                    alt="clean-text-button"
                    onClick={() => {
                      navigate(`/tools/style-guide-generator/`);
                    }}
                  />
                )}
              </div>

              <hr className="border border-[#B0B0B0] mt-2 mb-4" />

              <textarea
                dir={
                  inputLang === "fa" || inputLang === "he" || inputLang === "ar"
                    ? "rtl"
                    : "ltr"
                }
                ref={textareaRef}
                value={sourceText}
                dangerouslySetInnerHTML={{
                  __html: sourceText.replace(/\n/g, "<br />"),
                }}
                onChange={handleTextChange}
                className={`anonymize_tool  outline-none w-full resize-none text-base border-none min-h-[300px]`}
                placeholder="Enter your text here"
              />

              <div
                className={`mt-5 w-full bg-white  ${
                  outputText ? "lg:absolute  bottom-0 left-0 p-4 " : ""
                }`}
              >
                <div className="flex md:flex-row items-end flex-col gap-2.5 justify-between">
                  <div className="flex-1">
                    <p className="text-base text-[#9C9C9C] ">
                      {wordCount} words
                    </p>
                  </div>
                  <button
                    disabled={outputText || !sourceText}
                    className={` outline-none font-opensans font-semibold text-base flex items-center justify-center px-6 py-3 rounded-lg gap-3 border ${
                      !outputText
                        ? "bg-[#5B93FF] border-[#5B93FF]  text-white"
                        : "bg-[#E8E8E8] border-[#E8E8E8] "
                    }`}
                  >
                    {inputLang
                      ? "Generate Style Guide"
                      : "Generate Style Guide Link"}
                    {/* {loader ? <Loader /> : "Generate Style Guide"} */}
                  </button>
                </div>
              </div>
            </div>
            <StyleOutput outputText={outputText} error = {error} />
          
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans">
            Enter your text or project details and the tool generates a style
            guide to ensure consistency and accuracy in your translations. This
            helps streamline your workflow and deliver professional results.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={StyleGuideToolFaqData} />
    </Layout>
  );
};

export default Result;
