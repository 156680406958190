import React, { useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import DownloadSection from "utils/resultDownload";
import CopyIcon from "assets/copytools.jpg";
import * as XLSX from "xlsx";
import CopyLink from "assets/copu_link_icon.svg";

const StyleOutput = ({
  outputText,
  error,
  outputRef,
  tooltipContent,
  shareresult,
}) => {
  const [selectedFormat, setSelectedFormat] = useState("Download CSV");
  const downloadOutputFormat = ["Download CSV", "Download XLS"];
  const [copySuccess, setCopySuccess] = useState("Copy");

  const styleGuideEntries = outputText
    ? Object.entries(outputText).map(([term, definition]) => ({
        Term: term,
        Definition: definition,
      }))
    : [];
  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
      downloadText();
    } else if (format === "Download XLS") {
      downloadXLS();
    }
  };

  const downloadText = () => {
    if (styleGuideEntries && styleGuideEntries.length > 0) {
      const header = "Term,Definition\n";
      const rows = styleGuideEntries
        .map((item) => {
          const term = item.Term.replace(/,/g, "");
          const definition = item.Definition.replace(/,/g, "").replace(
            /\n/g,
            " "
          );
          return `"${term}","${definition}"`;
        })
        .join("\n");

      const csvContent = header + rows;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "style-guide.csv";
      link.click();
    }
  };
  const downloadXLS = () => {
    if (styleGuideEntries && styleGuideEntries.length > 0) {
      const data = styleGuideEntries.map((item) => ({
        Term: item.Term.replace(/,/g, ""),
        Definition: item.Definition.replace(/,/g, "").replace(/\n/g, " "),
      }));
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Style Guide");
      XLSX.writeFile(wb, "style-guide.xlsx");
    }
  };

  const copyText = () => {
    if (outputRef.current) {
      const textToCopy = outputRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  return (
    <div
      className={`relative shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans ${
        outputText && "border border-[#5B93FF]"
      }`}
    >
      <div className="flex justify-between items-center">
        <span
          className={`font-bold text-lg ${
            outputText ? "text-[#6E6E6E]" : "text-[#D2D2D2]"
          }`}
        >
         Generate Style Guide
        </span>
        <div className="flex gap-3">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>
          {shareresult && (
            <button
              className="flex items-center gap-2 w-max"
              data-tooltip-id="linkcopy-tooltip"
              data-tooltip-content={tooltipContent}
              onClick={shareresult}
            >
              <img src={CopyLink} alt="copy_link" />
            </button>
          )}
          <DownloadSection
            handleDownload={handleDownload}
            selectedFormat={selectedFormat}
            handleFormatChange={handleFormatChange}
            downloadOutputFormat={downloadOutputFormat}
          />

          <Tooltip
            id="copy-tooltip"
            place="top"
            multiline={true}
            className="z-50"
          />
         
        </div>
        <Tooltip
            id="linkcopy-tooltip"
            place="top"
            multiline={true}
            className="z-50"
          />
      </div>
      <hr
        className={`border mt-2  ${
          outputText ? "border-[#B0B0B0]" : "border-[#D2D2D2]"
        }`}
      />

      <div
        ref={outputRef}
        className={`mt-4 flex items-center justify-center  flex-col w-full overflow-y-auto overflow-x-hidden anonymize_tool  border-none pr-2 min-h-[225px] ${outputRef ? "max-h-[400px]" : "max-h-[400px]"} `}
      >
        {outputText ? (
          <div className="flex flex-col gap-2.5 w-full  pb-20" id="">
            {styleGuideEntries &&
              styleGuideEntries.map((item, index) => (
                <li className="flex" key={index}>
                  <p className="p-3 w-full lg:max-w-[200px] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                    {item.Term}
                  </p>
                  <p className="p-3 bg-[#F0F5FF] w-full  border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                    {item.Definition}
                  </p>
                </li>
              ))}
          </div>
        ) : (
          <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
            {error?.message}
          </span>
        )}
      </div>
    </div>
  );
};

export default StyleOutput;
